import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomePage')
  },
  {
    path: '/terms-conditions',
    name: 'TermsPage',
    component: () => import('@/views/TermsPage')
  },
  {
    path: '/privacy-policy',
    name: 'PolicyPage',
    component: () => import('@/views/PolicyPage')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      };
    }
    return { x: 0, y: 0 };  // Go to the top of the page if no hash
  },
  routes
})

export default router
