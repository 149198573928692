<template lang="pug">
  div#app
    HeaderBlock
    router-view
    FooterBlock
</template>

<script>
export default {
  name: 'App',
  components: {
    FooterBlock: () => import('@/components/FooterBlock.vue'),
    HeaderBlock: () => import('@/components/HeaderBlock.vue')
  }
}
</script>
